export const TERMS_OF_SERVICE_HREF = 'https://www.masterclass.com/terms'
export const AI_TERMS_HREF = 'https://learn.masterclass.com/ai-terms'
export const PRIVACY_POLICY_HREF = 'https://privacy.masterclass.com'
export const SPEECH_TO_TEXT_NOTICE_HREF =
  'https://privacy.masterclass.com/policies?name=speech-to-text-notice'
export const CALIFORNIA_PRIVACY_NOTICE_HREF =
  'https://privacy.masterclass.com/policies?name=california-privacy-notice'
export const DO_NOT_SELL_MY_PERSONAL_INFO_HREF =
  'https://privacy.masterclass.com/policies?modal=select-subject&name=california-privacy-notice'
export const SUPPORT_HREF = 'https://www.masterclass.com/help-center/oncall'
