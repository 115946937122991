import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { renderChildren } from '../children'
import { PROP_TYPE_CHILDREN } from '../constants'

type DropdownBodyProps = {
  children: React.ReactNode
  className?: string
} & React.HTMLAttributes<HTMLDivElement>

export default class DropdownBody extends PureComponent<DropdownBodyProps> {
  static propTypes = {
    children: PROP_TYPE_CHILDREN.isRequired,
    className: PropTypes.string,
  }

  render() {
    const { children, className, ...restProps } = this.props

    const classes = cn({
      'mc-dropdown__body': true,
      [className ?? '']: className,
    })

    return (
      <div className={classes} {...restProps}>
        {renderChildren(children, { role: 'option' })}
      </div>
    )
  }
}
