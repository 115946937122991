import { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { PROP_TYPE_CHILDREN } from '../constants'
import { renderChildren } from '../children'

const fullscreenEvents = [
  'fullscreenchange',
  'mozfullscreenchange',
  'webkitfullscreenchange',
  'msfullscreenchange',
] as const

declare global {
  interface Document {
    msFullscreenElement?: Element
    mozFullScreenElement?: Element
    webkitFullscreenElement?: Element
  }
}

interface FullscreenHandlerProps {
  onFullscreenChange?: () => void
  children: ({
    fullscreenElement,
  }: {
    fullscreenElement: Element | null
  }) => React.ReactElement
}

interface State {
  fullscreenElement: Element | null
}

export default class FullscreenHandler extends PureComponent<
  FullscreenHandlerProps,
  State
> {
  static propTypes = {
    onFullscreenChange: PropTypes.func,
    children: PROP_TYPE_CHILDREN.isRequired,
  }

  state = {
    fullscreenElement: null,
  }

  componentDidMount() {
    this.checkFullscreenState()
    fullscreenEvents.forEach((eventName) =>
      document.addEventListener(eventName, this.checkFullscreenState),
    )
  }

  componentWillUnmount() {
    fullscreenEvents.forEach((eventName) =>
      document.removeEventListener(eventName, this.checkFullscreenState),
    )
  }

  checkFullscreenState = () => {
    const fullscreenElement =
      document.fullscreenElement ??
      document.mozFullScreenElement ??
      document.webkitFullscreenElement ??
      document.msFullscreenElement ??
      null

    this.setState({ fullscreenElement })
  }

  render() {
    const { children } = this.props
    const { fullscreenElement } = this.state

    return renderChildren(children, { fullscreenElement })
  }
}
