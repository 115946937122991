import React, { PureComponent } from 'react'
import { string } from 'prop-types'
import cn from 'classnames'

import { Consumer } from '../Modal'
import ClickOutside from '../ClickOutside'
import { PROP_TYPE_CHILDREN } from '../constants'

type ModalContentProps = Pick<
  React.HTMLAttributes<HTMLDivElement>,
  | 'children'
  | 'className'
  | 'aria-label'
  | 'aria-labelledby'
  | 'aria-describedby'
>

export default class ModalContent extends PureComponent<ModalContentProps> {
  static propTypes = {
    children: PROP_TYPE_CHILDREN.isRequired,
    className: string,
    'aria-label': string,
    'aria-labelledby': string,
    'aria-describedby': string,
  }

  componentDidMount() {
    this.content.current?.focus()
  }

  content = React.createRef<HTMLDivElement>()

  render() {
    const {
      children,
      className,
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledBy,
      'aria-describedby': ariaDescribedBy,
    } = this.props

    const classes = cn({
      [className ?? '']: className,
      'mc-modal__content': true,
    })

    return (
      <Consumer>
        {({ close }) => (
          <ClickOutside
            externalInsideElements={[this.content]}
            onClickOutside={close('backdrop')}
          >
            <div
              className={classes}
              ref={this.content}
              role='dialog'
              aria-modal='true'
              aria-label={ariaLabel}
              aria-labelledby={ariaLabelledBy}
              aria-describedby={ariaDescribedBy}
            >
              {children}
            </div>
          </ClickOutside>
        )}
      </Consumer>
    )
  }
}
