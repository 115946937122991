import { useRouter } from 'next/router'

import { PageSources } from '../types/tracking'

export const useCtaToPurchaseUrl = () => {
  const { query } = useRouter()
  const { slug: instructor, page_source: pageSource, ...extra } = query

  const getCtaToPurchaseUrl = (ctaUrl: string, source: PageSources) => {
    // preserve any existing query params (utm params mostly)
    const queryParams = new URLSearchParams({
      ...extra,
      ...(pageSource && { page_source: pageSource }),
    } as { [key: string]: string })
    // replace page_source param if exists to the target source
    if (queryParams.has('page_source')) {
      queryParams.delete('page_source')
    }
    queryParams.append('page_source', source)
    if (instructor) {
      queryParams.append('instructor', instructor as string)
    }

    // Check if the ctaUrl already has query params
    const [baseUrl, existingParams] = ctaUrl.split('?')

    if (existingParams) {
      // If there are existing params, append them only if they don't already exist
      const existingQueryParams = new URLSearchParams(existingParams)
      existingQueryParams.forEach((value, key) => {
        if (!queryParams.has(key)) {
          queryParams.append(key, value)
        }
      })
    }

    return `${baseUrl}?${queryParams.toString()}`
  }

  return getCtaToPurchaseUrl
}

export const useCtaPreserveQueryParams = () => {
  const { query } = useRouter()

  const getUrlWithQueryParams = (
    ctaUrl: string,
    extraParams: { [key: string]: string } = {},
  ) => {
    // preserve any existing query params (page_source, instructor, slug, utm, etc)
    const queryParams = new URLSearchParams({ ...query, ...extraParams } as {
      [key: string]: string
    })
    const queryParamsStr = queryParams.toString()
    return `${ctaUrl}${queryParamsStr ? `?${queryParamsStr}` : ''}`
  }

  return getUrlWithQueryParams
}
