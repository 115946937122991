import React from 'react'
import { useRouter } from 'next/router'

import { DropdownBody, DropdownHeader, DropdownItem } from '@mc/design-system'
import { useTranslation } from '@mc/i18n'

import { useAuth0User } from '../../hooks/useAuth0User'
import { usePersonaEvents } from '../../events'
import { paths } from '../../utils/paths'
import { SUPPORT_HREF } from '../../utils/legal'

const location = 'account management page (post-login)'
const level = 'secondary'

interface AccountManagementProps {
  onClickLogout: () => void
  showAccountManagement?: boolean
  showHelpCenter?: boolean
  pageResult?: string
}

export const AccountManagementDropdown = ({
  onClickLogout,
  showAccountManagement = true,
  showHelpCenter = true,
  pageResult,
}: AccountManagementProps) => {
  const { t } = useTranslation('@mc/persona')
  const { user } = useAuth0User()
  const { trackPersonaClick } = usePersonaEvents()
  const { push } = useRouter()

  const trackClickAction = (action: string) =>
    trackPersonaClick({
      location,
      action,
      level,
      additionalAnalytics: { page: pageResult },
    })

  const handleSettingsClick = () => {
    trackClickAction('settings')
    push(paths.settings)
  }

  const handleLogoutClick = () => {
    onClickLogout()
    trackClickAction('sign out')
  }

  const handleHelpCenterClick = () => {
    trackClickAction('help center')
  }

  return (
    <>
      <DropdownHeader className='mc-px-6 mc-py-3'>
        <p className='mc-text-h5 mc-mb-1'>{t('accountManagement.hello')}</p>
        <p
          className='mc-opacity--hinted mc-text--pre-wrap'
          style={{
            wordBreak: 'break-all',
          }}
        >
          {user?.email}
        </p>
      </DropdownHeader>
      <DropdownBody>
        {showAccountManagement && (
          <DropdownItem
            className='mc-px-6 mc-py-3'
            onClick={handleSettingsClick}
          >
            {t('accountManagement.settings.title')}
          </DropdownItem>
        )}
        {showHelpCenter && (
          <DropdownItem
            as='a'
            className='mc-px-6 mc-py-3'
            href={SUPPORT_HREF}
            target='_blank'
            rel='noreferrer noopener'
            onClick={handleHelpCenterClick}
          >
            {t('accountManagement.helpCenter')}
          </DropdownItem>
        )}
        <DropdownItem className='mc-px-6 mc-py-3' onClick={handleLogoutClick}>
          {t('accountManagement.logout')}
        </DropdownItem>
      </DropdownBody>
    </>
  )
}
