import React, { useEffect, useState } from 'react'

import { Button, Modal, ModalContent } from '@mc/design-system'
import { useTranslation } from '@mc/i18n'

import { usePersonaEvents } from '../../events'
import styles from './LogoutConfirmation.module.scss'

export const LogoutConfirmationModal = ({
  isOpen,
  onClose,
  onLogout,
}: {
  isOpen: boolean
  onClose: () => void
  onLogout: () => void
}) => {
  const { t } = useTranslation('@mc/persona')
  const { trackModalClicked, trackModalViewed } = usePersonaEvents()
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(false)

  useEffect(() => {
    if (isOpen) {
      trackModalViewed({
        modalName: 'log out warning',
        pageSource: 'account management page (post-login)',
      })
    }
  }, [isOpen, trackModalViewed])

  const handleConfirmClick = (confirm: boolean) => {
    trackModalClicked({
      modalName: 'log out warning',
      cta: confirm ? 'logout' : 'cancel',
      pageSource: 'account management page (post-login)',
    })

    if (confirm) {
      setIsConfirmDisabled(true)
      onLogout()
    } else {
      onClose()
    }
  }

  return (
    <>
      <Modal
        size='full'
        show={isOpen}
        onClose={onClose}
        containerClassName={styles.modalContainer}
        canClickOutsideToClose={false}
        className='d-block d-md-none'
      >
        <ModalContent>
          <div
            className='d-flex mc-text--center justify-content-center align-items-center mc-px-4'
            style={{ height: '100dvh' }}
          >
            <div className='mc-corners--lg mc-px-9 mc-py-8 mc-bg-gray-100'>
              <h1 className='mc-text-h2 mc-mb-9' style={{ fontSize: '24px' }}>
                {t('accountManagement.logoutModal.title')}
              </h1>
              <Button
                fullWidth
                kind='secondary'
                onClick={() => handleConfirmClick(true)}
                className='mc-mb-4 mc-corners--xl'
                disabled={isConfirmDisabled}
              >
                {t('accountManagement.logoutModal.confirm')}
              </Button>
              <Button
                fullWidth
                kind='tertiary'
                onClick={() => handleConfirmClick(false)}
                className='mc-corners--xl'
              >
                {t('accountManagement.logoutModal.cancel')}
              </Button>
            </div>
          </div>
        </ModalContent>
      </Modal>
      <Modal
        size='medium'
        show={isOpen}
        onClose={onClose}
        containerClassName={styles.modalContainer}
        canClickOutsideToClose={false}
        className='d-none d-md-block'
      >
        <ModalContent>
          <div
            className='d-flex mc-text--center align-items-center mc-px-4'
            style={{ height: '100dvh', width: '100%' }}
          >
            <div className='mc-corners--lg mc-px-9 mc-py-8 mc-bg-gray-100'>
              <h1 className='mc-text-h2 mc-mb-8' style={{ fontSize: '24px' }}>
                {t('accountManagement.logoutModal.title')}
              </h1>
              <Button
                fullWidth
                kind='secondary'
                onClick={() => handleConfirmClick(true)}
                className='mc-mb-4 mc-corners--xl'
                disabled={isConfirmDisabled}
              >
                {t('accountManagement.logoutModal.confirm')}
              </Button>
              <Button
                fullWidth
                kind='tertiary'
                onClick={() => handleConfirmClick(false)}
                className='mc-corners--xl'
              >
                {t('accountManagement.logoutModal.cancel')}
              </Button>
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  )
}
