import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import Image from 'next/image'

import {
  Button,
  IconArrowLeftDefault,
  IconChevronRightDefault,
  Modal,
  ModalContent,
} from '@mc/design-system'
import { useTranslation } from '@mc/i18n'

import styles from './AccountManagement.module.scss'
import { usePersonaEvents } from '../../events'
import { useAuth0User } from '../../hooks/useAuth0User'
import { paths } from '../../utils/paths'
import { SUPPORT_HREF } from '../../utils/legal'
import { cloudflareLoader } from '../Image'
import ProfilePlaceholder from '../../assets/mobile-profile-placeholder.svg'

interface AccountManagementProps {
  isOpen: boolean
  onClose: () => void
  onClickLogout: () => void
  showAccountManagement?: boolean
  showHelpCenter?: boolean
}

const location = 'account management page (post-login)'
const level = 'secondary'

export const AccountManagementMobile = ({
  isOpen,
  onClose,
  onClickLogout,
  showAccountManagement = true,
  showHelpCenter = true,
}: AccountManagementProps) => {
  const { trackPersonaClick, trackPersonaViewed } = usePersonaEvents()
  const { push } = useRouter()
  const { t } = useTranslation('@mc/persona')
  const { user } = useAuth0User()

  useEffect(() => {
    const handleResize = () => {
      if (isOpen) onClose()
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [isOpen, onClose])

  const trackClickAction = (action: string) =>
    trackPersonaClick({
      location,
      action,
      level,
      additionalAnalytics: { page: location },
    })

  const handleSettingsClick = () => {
    trackClickAction('settings')
    push(paths.settings)
  }

  const handleLogoutClick = () => {
    onClickLogout()
    trackClickAction('sign out')
  }

  const handleHelpCenterClick = () => {
    trackClickAction('help center')
  }

  useEffect(() => {
    if (isOpen) {
      trackPersonaViewed({
        page: location,
      })
    }
  }, [isOpen, trackPersonaViewed])

  return (
    <>
      <Modal
        size='full'
        show={isOpen}
        onClose={onClose}
        containerClassName={styles.modalContainer}
        canClickOutsideToClose={false}
        className='d-block d-md-none'
      >
        <ModalContent>
          <div className={`mc-pt-9 mc-px-4 mc-bg-dark ${styles.background}`}>
            <Button
              kind='tertiary'
              onClick={onClose}
              className='mc-p-2 mc-mb-4'
            >
              <IconArrowLeftDefault size='sm' />
            </Button>
            <div className='d-flex justify-content-between align-items-center mc-mb-9 flex-gap-4'>
              <div className='d-flex flex-column flex-gap-1 flex-grow-1'>
                <h2 className='mc-text-h2'>{user?.name}</h2>
                <p
                  className='mc-text-color--tint'
                  style={{
                    overflowWrap: 'break-word', // Allows text to wrap within its container
                    wordBreak: 'break-word', // Ensures long words also break
                  }}
                  title={user?.email}
                >
                  {user?.email}
                </p>
              </div>
              <div
                className='d-flex flex-shrink-0'
                style={{ minWidth: '32px', width: '32px' }}
              >
                <Image
                  className='mc-corners--circle'
                  src={user?.picture ?? ProfilePlaceholder}
                  alt={t('common.profileAlt')}
                  loader={cloudflareLoader}
                  width={32}
                  height={32}
                />
              </div>
            </div>
            {showAccountManagement && (
              <button
                className='mc-clickable d-flex justify-content-between align-items-center mc-py-6 mc-corners--none'
                style={{ width: '100%', borderTop: '1px solid #303136' }}
                onClick={handleSettingsClick}
              >
                <h2 className='mc-text-h6'>
                  {t('accountManagement.settings.title')}
                </h2>
                <IconChevronRightDefault />
              </button>
            )}
            {showHelpCenter && (
              <a
                className='mc-clickable d-flex justify-content-between align-items-center mc-py-6 mc-mb-5'
                style={{ width: '100%', borderTop: '1px solid #303136' }}
                href={SUPPORT_HREF}
                target='_blank'
                rel='noreferrer noopener'
                onClick={handleHelpCenterClick}
              >
                <h2 className='mc-text-h6'>
                  {t('accountManagement.helpCenter')}
                </h2>
                <IconChevronRightDefault />
              </a>
            )}
            <Button fullWidth kind='tertiary' onClick={handleLogoutClick}>
              <h2 className='mc-text-h6'>{t('accountManagement.logout')}</h2>
            </Button>
          </div>
        </ModalContent>
      </Modal>
    </>
  )
}
