import { useBucket } from '@mc/experiments-react'
import { trackExperimentViewed } from '@mc/track-event'
import { useEffect } from 'react'

// Growthbook experiment: https://app.growthbook.io/features/dais-2232_v1_persona_upcoming_beta
export const PERSONA_UPCOMING_BETA_FLAG = 'dais-2232_v1_persona_upcoming_beta'

export const useIsUpcomingBetaEnabled = () => {
  const bucket = useBucket<'control' | 'variant_1'>(PERSONA_UPCOMING_BETA_FLAG)

  useEffect(() => {
    if (bucket)
      trackExperimentViewed(
        PERSONA_UPCOMING_BETA_FLAG,
        {},
        { trackUserLeap: true },
      )
  }, [bucket])

  return bucket === 'variant_1'
}
