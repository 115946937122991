import React, { PureComponent } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import cn from 'classnames'
import FocusTrap from 'focus-trap-react'

import Backdrop from '../Backdrop'
import FullscreenHandler from '../FullscreenHandler'
import { PROP_TYPE_CHILDREN } from '../constants'

interface ModalContextType {
  close: (source: string) => (event: Event | React.SyntheticEvent) => void
}

export const ModalContext = React.createContext<ModalContextType>({
  close: () => () => {},
})

export const { Provider, Consumer } = ModalContext

const isInsideModalViewport = (element: EventTarget | null): boolean => {
  /**
   * This function checks if the clicked element is inside the modal viewport.
   * It was added to address the issue with the Cookies Consent Banner and other clickable elements that might exist outside the viewport.
   * We want to ensure that clicking on these elements does not affect the behavior of the current modal.
   */

  if (element === null) return false

  return !!(element as HTMLElement).closest('.mc-modal__viewport')
}

interface ModalProps
  extends Pick<React.HTMLAttributes<HTMLDivElement>, 'children' | 'className'> {
  backdrop: 'dark' | 'extra-dark'
  canClickOutsideToClose?: boolean
  stopEventPropagationOnClose?: boolean
  containerClassName?: string
  closeButton?: boolean
  show?: boolean
  size?: 'small' | 'medium' | 'large' | 'full'
  appendToBody?: boolean
  nobackdrop?: boolean
  onClose?: (source?: string, event?: Event | React.SyntheticEvent) => void
  noInitialFocus?: boolean
}

export default class Modal extends PureComponent<ModalProps> {
  static propTypes = {
    backdrop: PropTypes.oneOf(['dark', 'extra-dark']),
    canClickOutsideToClose: PropTypes.bool,
    stopEventPropagationOnClose: PropTypes.bool,
    children: PROP_TYPE_CHILDREN.isRequired,
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    closeButton: PropTypes.bool,
    show: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large', 'full']),
    appendToBody: PropTypes.bool,
    nobackdrop: PropTypes.bool,
    onClose: PropTypes.func,
    initialFocus: PropTypes.bool,
  }

  static defaultProps = {
    appendToBody: true,
    backdrop: 'dark',
    canClickOutsideToClose: true,
    stopEventPropagationOnClose: true,
    size: 'full',
    noInitialFocus: false,
  }

  componentDidMount() {
    const { show } = this.props

    if (show) {
      const rootHtml = document.getElementsByTagName('html')[0]
      rootHtml.classList.add('mc-modal__html--open')
    }
  }

  componentWillUnmount() {
    const rootHtml = document.getElementsByTagName('html')[0]
    rootHtml.classList.remove('mc-modal__html--open')
  }

  componentDidUpdate(prevProps: ModalProps) {
    const { show } = this.props
    const rootHtml = document.getElementsByTagName('html')[0]

    if (!prevProps.show && show) {
      rootHtml.classList.add('mc-modal__html--open')
    } else if (prevProps.show && !show) {
      rootHtml.classList.remove('mc-modal__html--open')
    }

    if (!prevProps.show && show) {
      const elem = this.container.current
      window.setTimeout(() => {
        if (elem) {
          elem.scrollTop = 0
        }
      }, 0)
    }
  }

  onKeyDown = (event: React.KeyboardEvent) => {
    if (event.keyCode === 27) {
      this.close('escape')(event)
    }
  }

  close = (source: string) => (event: Event | React.SyntheticEvent) => {
    const { canClickOutsideToClose, onClose, stopEventPropagationOnClose } =
      this.props

    if (!isInsideModalViewport(event.target)) return

    if (source === 'backdrop' && !canClickOutsideToClose) return

    if (onClose) {
      if (stopEventPropagationOnClose) {
        event.preventDefault()
        event.stopPropagation()
      }
      onClose(source, event)
    }
  }

  container = React.createRef<HTMLDivElement>()

  renderModal = () => {
    const {
      backdrop,
      children,
      className,
      containerClassName,
      size,
      nobackdrop = false,
      noInitialFocus,
    } = this.props

    const classes = cn('mc-modal', {
      [`mc-modal--${size}`]: size,
      [className ?? '']: className,
    })

    const containerClasses = cn('container', {
      [containerClassName ?? '']: containerClassName,
    })

    return (
      <Provider value={{ close: this.close }}>
        <FocusTrap
          focusTrapOptions={{
            fallbackFocus: '#dialog-container',
            allowOutsideClick: true,
            ...(noInitialFocus ? { initialFocus: false } : {}),
          }}
        >
          <div
            className={classes}
            onKeyDown={this.onKeyDown}
            ref={this.container}
          >
            {!nobackdrop && (
              <Backdrop className='mc-modal__backdrop' kind={backdrop} />
            )}
            <div className='mc-modal__viewport'>
              <div
                id='dialog-container'
                tabIndex={-1}
                className={containerClasses}
              >
                {children}
              </div>
            </div>
          </div>
        </FocusTrap>
      </Provider>
    )
  }

  getRootNode = (fullscreenElement: Element | null) => {
    let rootNode

    if (
      fullscreenElement &&
      fullscreenElement.closest('.mc-modal') !== this.container.current
    ) {
      rootNode = fullscreenElement
    } else {
      rootNode = document.body
    }

    return rootNode
  }

  render() {
    if (!this.props.show) return null

    if (this.props.appendToBody) {
      return (
        <FullscreenHandler>
          {({ fullscreenElement }) =>
            createPortal(
              this.renderModal(),
              this.getRootNode(fullscreenElement),
            )
          }
        </FullscreenHandler>
      )
    }

    return this.renderModal()
  }
}
