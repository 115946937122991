/* istanbul ignore file */
import * as React from 'react'
import type { SVGProps } from 'react'
import PropTypes from 'prop-types'

interface Props extends SVGProps<SVGSVGElement> {
  srText?: string
  size?: 'sm' | 'md' | 'lg' | 'scale-8' | 'scale-10' | number
  alt?: string
}
function IconArrowLeftDefault({
  srText,
  size = 'md',
  className = '',
  ...props
}: Props) {
  const newClassName = `mc-icon mc-icon--${size} ${className}`
  return (
    <>
      {!!srText && <span className='mc-sr-only'>{srText}</span>}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={25}
        fill='none'
        viewBox='0 0 24 24'
        aria-hidden='true'
        role='img'
        className={newClassName}
        {...props}
      >
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M11.13 22.175c.43.433 1.127.433 1.557 0 .43-.434.43-1.138 0-1.572l-6.961-7.025h15.173c.608 0 1.101-.498 1.101-1.111 0-.614-.493-1.112-1.1-1.112H5.791l6.895-6.958c.43-.434.43-1.138 0-1.572a1.094 1.094 0 0 0-1.557 0l-8.808 8.89a1.12 1.12 0 0 0 0 1.57z'
          clipRule='evenodd'
        />
      </svg>
    </>
  )
}
IconArrowLeftDefault.propTypes = {
  srText: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
} as PropTypes.ValidationMap<Props>
export default IconArrowLeftDefault
