import React from 'react'

import NextImage, {
  ImageLoaderProps,
  ImageProps as NextImageProps,
} from 'next/legacy/image'

// URLs
const ARTICLE_IMAGE_BASE = 'https://images.ctfassets.net'
const STATIC_IMAGE_BASE = 'https://static.masterclass.com'
const CLOUDFLARE_IMAGE_PREFIX = 'https://www.masterclass.com/cdn-cgi/image'

export type ImageProps = Omit<NextImageProps, 'loader'>

export const cloudflareLoader = ({
  src,
  width,
  quality = 75,
  format = 'webp',
}: ImageLoaderProps & { format?: string }) => {
  if (src.includes('ctfassets.net')) {
    const regex = /w=([^&]*)/
    const match = regex.exec(src)

    // if the w parameter is found, replace its value with the desired width
    if (match !== null) {
      const currentValue = match[1]
      // Already optimized image src url coming from BE, change manually the width
      return src.replace(`w=${currentValue}`, `w=${width}`)
    }

    return `${src}?w=${width}`
  }

  const params = []
  params.push([`width=${width}`])
  params.push(`quality=${quality}`)
  params.push(`format=${format}`)

  if (src.includes(STATIC_IMAGE_BASE) || src.includes(ARTICLE_IMAGE_BASE)) {
    return `${CLOUDFLARE_IMAGE_PREFIX}/${params.join(',')}/${src}`
  }

  return `${src}?${params.join('&')}`
}

const Image = ({ src, ...props }: ImageProps) => {
  if (!src) return <></>
  return <NextImage {...props} loader={cloudflareLoader} src={src} />
}

export default Image
