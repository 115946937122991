/* istanbul ignore file */
import * as React from 'react'
import type { SVGProps } from 'react'
import PropTypes from 'prop-types'

interface Props extends SVGProps<SVGSVGElement> {
  srText?: string
  size?: 'sm' | 'md' | 'lg' | 'scale-8' | 'scale-10' | number
  alt?: string
}
function IconWarningDefault({
  srText,
  size = 'md',
  className = '',
  ...props
}: Props) {
  const newClassName = `mc-icon mc-icon--${size} ${className}`
  return (
    <>
      {!!srText && <span className='mc-sr-only'>{srText}</span>}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={25}
        fill='none'
        viewBox='0 0 24 24'
        aria-hidden='true'
        role='img'
        className={newClassName}
        {...props}
      >
        <path
          fill='currentColor'
          d='m21.809 19.417-8.33-15.342a1.64 1.64 0 0 0-.614-.631 1.7 1.7 0 0 0-1.722 0c-.26.152-.472.37-.614.63L2.2 19.418a1.58 1.58 0 0 0 .05 1.614c.152.233.362.424.612.555.249.132.529.2.813.196h16.66c.287 0 .57-.072.819-.208.25-.137.459-.334.607-.572a1.58 1.58 0 0 0 .049-1.585M11.17 9.67c0-.215.088-.42.244-.571a.85.85 0 0 1 .589-.237c.22 0 .433.085.59.237a.8.8 0 0 1 .243.57v4.845c0 .214-.088.42-.244.571a.85.85 0 0 1-.589.237.85.85 0 0 1-.589-.237.8.8 0 0 1-.244-.57zm.875 9.285a1.3 1.3 0 0 1-.879-.347 1.22 1.22 0 0 1-.388-.84 1.16 1.16 0 0 1 .344-.868q.173-.174.402-.27a1.26 1.26 0 0 1 1.358.25c.236.221.374.522.387.84a1.16 1.16 0 0 1-.343.868 1.24 1.24 0 0 1-.881.367'
        />
      </svg>
    </>
  )
}
IconWarningDefault.propTypes = {
  srText: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
} as PropTypes.ValidationMap<Props>
export default IconWarningDefault
